import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      Contact details will appear here.
    </Layout>
  );
};

export default ContactPage;
